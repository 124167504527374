<template>
    <v-dialog :value="open" scrollable max-width="700px" persistent>
        <v-card>
            <v-card-title class="primary white--text font-weight-medium">Create Webhook</v-card-title>
            <v-divider></v-divider>
            <v-row class="ma-0 w-100" align="center" justify="center" v-if="getLoading" style="height: 400px;">
                <div class="setting-spinner-loader"></div>
            </v-row>
            <v-form lazy-validation @submit.prevent="onSubmit" v-else>
                <v-card-text style="max-height: 500px;">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" >
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">URL</v-subheader>
                            <v-text-field v-model="model.endpoint" :error-messages="$helpers.errorMsg('name', $v.model.endpoint, 'URL')"
                                placeholder="Enter Endpoint URL" outlined ref="endpoint" @input="$v.model.endpoint.$touch()"  @blur="$v.model.url.$touch()"
                                required hide-details="auto"/>
                        </v-col>
                        <v-col cols="12">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-subheader>
                            <v-textarea hide-details="auto" class="mb-3" auto-grow
                                rows="4" v-model="model.description" row-height="20" placeholder="Description" outlined
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" v-if="agentId && model.secret" class="d-flex">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Secret:</v-subheader>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip :color="copyTxt? 'success':'info'" small label :outlined="copyTxt" id="copy-text"
                                      @click="toogleCopyTxt()" ref="secretKey" :ripple="false" class="ml-2"
                                      v-bind="attrs" v-on="on">
                                        {{ model.secret }}
                                        <v-icon size="13" class="ml-2" @click="toogleCopyTxt()">mdi-content-copy</v-icon>
                                    </v-chip>
                                </template>
                                <span>{{copyTxt ? 'Copied!' : 'Copy'}}</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Events</v-subheader>
                            <v-autocomplete v-model="model.events" :items="webEventsList" :error-messages="$helpers.errorMsg('name', $v.model.events, 'Events')"
                                placeholder="Select Event" outlined ref="events" @input="$v.model.events.$touch()"  @blur="$v.model.events.$touch()"
                                required hide-details="auto" multiple small-chips>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip close  @click:close="model.events.splice(index, 1)">
                                        <span class="text-capitalize">{{ item.text }}</span>
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-5">
                    <v-spacer />
                    <v-btn color="primary" type="button" class="mr-4" outlined @click="$emit('close')"
                        :disabled="loading">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" type="submit" :loading="loading">Submit</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from 'vuex';

export default {
    props: ['open'],
    emits: ['close', 'save'],
    data: (vm) => ({
        agentId: vm.$route.params.agentId,
        model: {},
        getLoading: false,
        loading: false,
        copyTxt: false
    }),
    validations() {
        return {
            model: {
                endpoint: { required},
                events: { required },
            }
        }
    },
    computed: {
        ...mapGetters([ 'webEventsList']),
    },
    created() {
        this.getWebEventLists();
        if(this.open && this.open != 'create') this.getModel();
    },
    methods: {
        ...mapActions(['getSelectedWebhook', 'createWebhook', 'updateSelectedWebhook', 'getWebEventLists']),

        getModel() {
            const self = this;
            this.getLoading = true;
            this.getSelectedWebhook(this.open).then(response => {
                self.model = response;
                self.getLoading = false;
            }).catch(err => self.getLoading = false);
        },
        toogleCopyTxt() {
            if (!this.copyTxt) {
                this.$helpers.copyTextFunc(this.$refs.secretKey, 'noInput');
                this.copyTxt = true;
                setTimeout(() => {
                    this.copyTxt = false;
                }, 5000);
            }
        },
        onSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            const self = this;
            this.loading = true;
            this.model.agent_id = this.agentId;
            if (this.open != 'create') {
                this.updateSelectedWebhook(this.model).then(resp => {
                    self.loading = false;
                    self.$emit('save');
                }).catch(err => self.loading = false);
            } else {
                this.createWebhook(this.model).then(resp => {
                    self.loading = false;
                    self.$emit('save');
                }).catch(err => self.loading = false);
            }
        }
    }
}
</script>

<style>

</style>